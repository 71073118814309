.App {
  text-align: auto;
  display: "flex";
  flex-direction: "row-reverse";
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  background-color: #8a9ec7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.SideBar {
  height: 100vh;
  display: "flex";
  flex-direction: row-reverse;
  // color: "#000000";
}

.png-Logo {
  // margin-left: auto;
  // margin-right: auto;
}